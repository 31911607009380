body {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


/* Header */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.logo-container {
  width: 140px; 
}

.logo {
  width: 100%; 
}

.navbar {
  flex-grow: 1;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.nav-list li {
  margin-left: 20px;
}

.nav-list li:first-child {
  margin-left: 0;
}

.nav-list li a {
  text-decoration: none;
  color: #bf2026;
  font-weight: bold;
  font-size: 20px;
  transition: color 0.3s ease;
}

.nav-list li a:hover {
  color: #555;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar {
    position: absolute; 
    top: 1rem; 
    right: 1rem; 
    margin-top: 20px;
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-list li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .header {
    margin-bottom: 1rem;
  }
}


/* Footer */

footer {
  color: white;
  text-align: center;
  padding: 3rem 0;
}

.contact-section {
  background-color: #2c3e50;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 2rem
}

.contact-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: white;
}

.contact-links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.contact-links a {
  display: inline-block;
  margin-right: 3rem;
  color: white;
  text-decoration: none;
}

.contact-links a:hover {
  text-decoration: underline;
}

.footer-container p {
  color: white;
  margin: 0;
}

.copyright {
  background-color: black;
  padding: 1rem;
  font-size: 12px;
  margin-bottom: -2.5rem;  /*to remove the white spave under the footer*/
}

.contact-section h2 {
  padding-bottom: 3rem;
  font-size: 35px;
}

@media screen and (max-width: 768px) {
  .contact-links {
    flex-direction: column;
    align-items: center;
  }

  .contact-links a {
    margin-bottom: 1rem;
    margin-right: 0;
  }


  .contact-section h2 {
    font-size: 28px;
  }

  .footer-container {
    width: 100%;
  }
}


/* Contact Page */

.contact-page-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.contact-page-title {
  font-size: 3em;
  color: black;
}

.contact-page-subtitle {
  font-size: 2.5em;
  color: #bf2026;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  margin-top: 2rem;
}

.profile-image-contact-page img {
  width: 100%;
  border-radius: 0.5rem;
}

.contact-page-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5rem;
  margin-top: 2rem;
}

.contact-page-details p {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact-page-details img {
  margin-right: 1rem;
  width: 4em;
  height: 4em;
}

.contact-page-details a {
  color: black;
  text-decoration: none;
}

.red-text {
  color: #bf2026;
}

@media (max-width: 834px) {
  .profile-image-contact-page img {
    width: 200%;
  }
}

@media (max-width: 780px) {
  .profile-image-contact-page img {
    width: 150%;
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .contact-page-details {
    order: 1;
    font-size: 0.8em;  
    /* align-self: center;  
    text-align: center;  */
    margin-left: 2rem;
  }

  .profile-image-contact-page {
    order: 2;
    align-self: center; 
  }

  .profile-image-contact-page img {
    width: 80%; 
    margin: 0 auto; 
  }

  .contact-page-container {
    width: 100%; 
    text-align: center; 
  }
}

@media (max-width: 480px) {
  .contact-page-container {
    width: 100%;  
  }

  .profile-image-contact-page img {
    width: 80%; 
    margin: 0 auto; 
  }
}


/* Skills Page */

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.skills-container section {
  position: relative;
  background-color: #000;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  transition: transform 0.3s ease;
}

.skills-container section:hover {
  transform: scale(1.05);
}

.skills-container h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.skills-container ul {
  list-style-type: none;
  padding: 0;
}

.skills-container li {
  margin-bottom: 0.5rem;
}

.skills-container .programming-languages,
.skills-container .frameworks-tools,
.skills-container .databases,
.skills-container .apps,
.skills-container .other {
  flex-basis: 20%;
}

.skills-title {
  font-size: 2rem;
  font-weight: bold;
  color: #bf2026;
  text-align: center;
  margin-bottom: 2rem;
  text-decoration: underline;
}


@media (max-width: 768px) {

  .skills-container {
    width: 100%;
    }
  .skills-container {
    flex-direction: column;
    align-items: stretch;
  }

  .skills-container section {
    flex-basis: auto;
    width: 80%;
    margin: 1rem auto;
  }

  .skills-container h3 {
    font-size: 1.2rem;
  }

  .skills-container li {
    font-size: 0.8rem;
  }
}



/* Homepage */


.home-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
}

.home-content {
  flex: 1;
  padding: 1rem;
  text-align: center;
}

.home-greeting {
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.home-name {
  color: #000;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.home-subtitle {
  color: #000;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.home-role {
  color: #bf2026;
}

.home-hr {
  border: none;
  border-top: 2px solid #bf2026;
  margin: 2rem 0;
}

.home-description {
  color: #000;
  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: 2rem;
}

.home-buttons {
  display: flex;
  justify-content: center;
}

.home-button {
  background-color: #bf2026;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none; 
}

.home-button:hover {
  background-color: #fff;
  color: #bf2026;
  border: 1px solid #bf2026;
}

.home-image-container {
  width: 100%;
  max-width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.home-image:hover {
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .home-container {
    flex-direction: row;
  }
  
  .home-content {
    padding-right: 2rem;
    margin-left: 2rem;
    margin-top: 2rem;
    text-align: left;
  }

  .home-description {
    padding-right: 12rem;
  }

  .home-buttons {
    justify-content: flex-start;
  }

  .home-image-container {
    margin-right: 3rem;
    margin-top: 0;
  }
} 


/* Contact Page Thanks */

.thanks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 90vh;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-left: 40%;
}

.thanks-heading {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin-bottom: 1rem;
}

.thanks-message {
  color: black;
  margin-bottom: 2rem;
}

.home-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #2c3e50;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.home-button:hover {
  background-color: #1c2833;
}

/* About Page */

.about-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.headshot-container {
  display: block;
  margin: 0 auto;
}

.headshot-image {
  width: 50%;
  height: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.contact-button, .contact-button-resume {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #bf2026; 
  color: white;
  border: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.contact-button {
  margin-right: 20px; 
}

.title-about-page {
  font-size: 4em; 
  margin-top: 1.5rem;
}

.about-container p {
  margin-bottom: 1.5rem;
}

.name-me-about {
  color: #bf2026;
  margin-top: -0.5rem;
}

/* Smaller devices (landscape phones, 576px and up) */
@media (max-width: 576px) { 
  .headshot-image {
    width: 100%;
  }

  .title-about-page {
    font-size: 2.5em;
    margin-top: 1rem;
  }

  .about-container p {
    margin-bottom: 1rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 768px) { 
  .headshot-image {
    width: 75%;
  }

  .title-about-page {
    font-size: 3em;
    margin-top: 1.2rem;
  }

  .about-container p {
    margin-bottom: 1.2rem;
  }
}


/* Projects Page */

.project-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  border: 0.5px solid rgba(66, 65, 65, 0.209);
}

@media (min-width: 768px) {
  .project-card {
    grid-template-columns: 1fr 2fr;
  }
}

.image-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 7px;
  border: 0.5px solid rgba(66, 65, 65, 0.209);
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.project-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.github-button,
.live-site-button {
  background-color: #bf2026;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.github-button:hover,
.live-site-button:hover {
  background-color: #911a1f;
}
